import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

// const app = createApp(App);
// app.config.globalProperties.$axios = axios;

axios.interceptors.response.use(function (response) {
  // if ("errornumber" in response.data) {
  //   if (response.data.errornumber == 704) {
  //     router.push("/login");
  //   }
  // }
  return response;
});

createApp(App).use(store).use(router).use(VueAxios, axios).mount("#app");
