<template>
  <div class="home container">
    <div class="row">
      <div class="">
        <h1>
          Adressen
          <small class="text-muted">({{ getTotalRecords }})</small>
        </h1>
        <AddressDownload />
        <AddressFilter />
      </div>
    </div>
    <AddressDetail :address="address" />
    <table class="table table-striped table-hover">
      <thead>
        <th>Firma Naam</th>
        <th>Contact Persoon</th>
        <th>Adres</th>
        <th>Postcode</th>
        <th>Plaats</th>
      </thead>
      <tbody>
        <tr
          v-for="address in getAddresses"
          :key="address.DOSSIERNR"
          @click="setAddress(address)"
          data-bs-toggle="modal"
          data-bs-target="#detailModal"
        >
          <td>
            {{ address.FANAAM }}
          </td>
          <td>
            {{ address.CPTITEL }}
            {{ address.CPVOORL }}
            {{ address.CPTUSSENV }}
            {{ address.CPACHTER }}
          </td>
          <td>
            {{ address.STRAAT }} {{ address.HUISNR }} {{ address.HUISNRTOEV }}
          </td>
          <td>
            {{ address.POSTCODE }}
          </td>
          <td>
            {{ address.WOONPLAATS }}
          </td>
        </tr>
      </tbody>
    </table>
    <AddressPagination />
  </div>
</template>

<script>
import AddressPagination from "@/components/AddressPagination.vue";
import AddressFilter from "@/components/AddressFilter.vue";
import AddressDetail from "@/components/AddressDetail.vue";
import AddressDownload from "@/components/AddressDownload.vue";

export default {
  name: "AddressesView",
  components: {
    AddressPagination,
    AddressFilter,
    AddressDetail,
    AddressDownload,
  },
  data() {
    return {
      address: {},
    };
  },
  computed: {
    getAddresses() {
      return this.$store.getters.getAddresses;
    },
    getTotalRecords() {
      return this.$store.getters.getTotalRecords;
    },
  },
  created() {
    // let filterParams = {
    //   "filter[POSTCODE][$regex]": "1094MH",
    //   "filter[HUISNR][$regex]": 476,
    // };
    // this.$store.dispatch("setFilter", filterParams);
    this.$store.dispatch("fetchAddresses");
  },
  methods: {
    setAddress(address) {
      this.address = address;
    },
  },
};
</script>

<style lang="css" scoped>
tr {
  cursor: pointer;
}
</style>
