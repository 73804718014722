import axios from "axios";
import router from "../../router";

export default {
  state: {
    token: null,
  },
  getters: {
    getToken() {
      let token = localStorage.getItem("token");
      return token;
    },
  },
  mutations: {
    setToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
  },
  actions: {
    createUserSession(state, payload) {
      var params = new URLSearchParams();
      params.append("user", payload.username);
      params.append("password", payload.password);
      // console.log(params);
      axios
        .post("https://dcklinieken.quantes.nl/db/api/public/auth", params)
        .then((response) => {
          // console.log(response);
          state.commit("setToken", response.data.api_key);
          state.commit("setUserPreferences", JSON.stringify(response.data));
          router.push("/");
        })
        .catch((err) => console.log(err));
    },
    setToken(state, payload) {
      state.commit("setToken", payload);
      router.push("/");
    },
    logout(state) {
      state.commit("setToken", null);
      state.commit("setUserPreferences", null);
      router.push("/login");
    },
  },
  modules: {},
};
