export default {
  state: {
    userPreferences: null,
  },
  getters: {
    getUserPreferences(state) {
      let userPreferences = null;
      if (state.userPreferences) {
        userPreferences = state.userPreferences;
      } else {
        userPreferences = localStorage.getItem("userPreferences");
      }
      return userPreferences;
    },
  },
  mutations: {
    setUserPreferences(state, userPreferences) {
      state.userPreferences = userPreferences;
      localStorage.setItem("userPreferences", userPreferences);
    },
  },
  actions: {},
  modules: {},
};
