<template>
  <div class="h-100">
    <main class="form-signin pt-5">
      <form>
        <!-- <img class="mb-4" src="/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"> -->
        <h1 class="h3 mb-3 fw-normal">Token</h1>
        <div class="form-floating">
          <input
            type="text"
            v-model="token"
            class="form-control"
            placeholder="name@example.com"
          />
        </div>
        <button
          class="w-100 btn btn-lg btn-primary mt-3"
          type="submit"
          @click="setToken()"
        >
          Send
        </button>
        <p class="mt-2 mb-3 text-muted">© Dc Klinieken {{ getYear() }}</p>
      </form>
    </main>
  </div>
</template>

<script>
export default {
  name: "TokenView",
  data() {
    return {
      token: "",
    };
  },
  computed: {
    isDisabled() {
      if (this.token == "") {
        return true;
      }
      return false;
    },
  },
  methods: {
    setToken: function () {
      this.$store.dispatch("setToken", this.token);
    },
    getYear: function () {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style media="screen">
.login-button {
  width: 100%;
}
.form-item {
  margin-bottom: 10px;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
