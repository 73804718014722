<template lang="html">
  <div class="float-start">
    <button
      type="button"
      class="btn btn-primary me-md-2"
      @click="downloadAddresses()"
      v-if="hasFilter && hasAddresses"
    >
      Download
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddressDownload",
  data() {
    return {};
  },
  created() {},
  computed: {
    hasFilter() {
      let filter = this.$store.getters.getFilter;
      if (filter.length > 0) {
        return true;
      }
      return false;
    },
    hasAddresses() {
      if (this.$store.getters.getTotalRecords > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    downloadAddresses() {
      var params = new URLSearchParams();
      let filters = this.$store.getters.getFilterParams;
      for (var filter in filters) {
        params.append(filter, filters[filter]);
      }
      let token = this.$store.getters.getToken;
      params.append("token", token);
      axios
        .get("https://dcklinieken.quantes.nl/addressDownload.php", {
          params: params,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = "addresses.csv";
          a.click();
          window.URL.revokeObjectURL(url);

          // ### BELOW MAYBE FOR LATER REFERENCE!!
          // window.open(url);
          // let fileURL = window.URL.createObjectURL(new Blob([response]));
          // let fileLink = document.createElement("a");
          //
          // // fileLink.href = fileURL;
          // // fileLink.setAttribute("download", "address.csv");
          // fileLink.href = "data:text/csv;charset=utf-8," + fileURL;
          // fileLink.target = "_blank";
          // fileLink.download = "address.csv";
          // fileLink.click();
          // document.body.appendChild(fileLink);
          // fileLink.click();
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
