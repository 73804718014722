export default {
  state: {
    filter: [],
    filterParams: {},
  },
  getters: {
    getFilter(state) {
      let filter = state.filter;
      if (Array.isArray(filter)) {
        return filter;
      }
      return [];
    },
    getFilterParams(state) {
      return state.filterParams;
    },
  },
  mutations: {
    setFilter(state, filter) {
      state.filter = filter;
    },
    setFilterParams(state, filterParams) {
      state.filterParams = filterParams;
    },
    removeFilter(state, index) {
      state.filter.splice(index, 1);
    },
  },
  actions: {
    clearFilter(state) {
      state.commit("setFilter", []);
      state.commit("setFilterParams", []);
    },
    addToFilter(state, newFilter) {
      let filter = state.getters.getFilter;

      filter.push(newFilter);
      state.commit("setFilter", filter);
    },
    setFilterParams(state, filterParams) {
      state.commit("setFilterParams", filterParams);
    },
    removeFilter(state, index) {
      state.commit("removeFilter", index);
    },
  },
  modules: {},
};
