<template>
  <NavBar v-if="currentRouteName != 'login' && currentRouteName != 'token'" />
  <!-- <el-main> -->
  <router-view class="h-100" />
  <!-- </el-main> -->
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">
@import "styles/custom.scss";
@import "bootstrap/scss/bootstrap.scss";
</style>
