<template lang="html">
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item" v-if="page != 1" @click="setPage(page - 1)">
        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
          Previous
        </a>
      </li>
      <li
        class="page-item"
        :class="page == pageNum ? 'disabled' : ''"
        v-for="pageNum in pages.slice(page - 1, page + 5)"
        v-bind:key="pageNum"
        @click="setPage(pageNum)"
      >
        <a class="page-link" href="#">{{ pageNum }}</a>
      </li>
      <li
        class="page-item"
        v-if="page < pages.length"
        @click="setPage(page + 1)"
      >
        <a class="page-link" href="#">Next</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "AddressPagination",
  computed: {
    getTotalRecords() {
      return this.$store.getters.getTotalRecords;
    },
    page() {
      return this.$store.getters.getPage;
    },
    pages() {
      let pages = [];
      let totalRecords = this.$store.getters.getTotalRecords;
      let recordsPerPage = this.$store.getters.getRecords;
      let numberOfPages = Math.ceil(totalRecords / recordsPerPage);
      for (let index = 1; index <= numberOfPages; index++) {
        pages.push(index);
      }
      return pages;
    },
  },
  methods: {
    setPage(page) {
      this.$store.dispatch("setPage", page);
    },
  },
};
</script>

<style lang="css" scoped></style>
