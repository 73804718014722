<template>
  <nav
    class="navbar navbar-transparent navbar-expand-lg navbar-light bg-light mb-4"
  >
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img class="navbar-logo" alt="Vue logo" src="../assets/logo.png" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              v-bind:class="{ active: currentRouteName == 'home' }"
              class="nav-link"
              aria-current="page"
              to="/"
            >
              <span class="gradient-text">Addressen</span>
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="logout()">Log uit</a>
          </li>
          <!-- <li class="nav-item">
            <router-link
              v-bind:class="{ active: currentRouteName == 'invoices' }"
              class="nav-link"
              to="/invoices"
            >
              Facturen
            </router-link>
          </li> -->
        </ul>
        <ul class="navbar-nav">
          <!-- <li class="nav-item dropdown" v-if="userPreferences">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-file-person-fill"></i>
              {{ userPreferences.name }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#" @click="logout()">Log uit</a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item dropdown">
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- <li><a class="dropdown-item" href="#">Wachtwoord wijzigen</a></li> -->
              <!-- <li><a class="dropdown-item" href="#">Another action</a></li> -->
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#" @click="logout()">Log uit</a>
              </li>
            </ul>
          </li>
        </ul>
        <!-- <form class="d-flex">
          <button class="btn btn-outline-primary" type="submit">Contact</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    userPreferences() {
      let userPreferences = JSON.parse(this.$store.getters.getUserPreferences);
      return userPreferences;
    },
    userToken() {
      let token = this.$store.getters.getToken;
      return token;
    },
  },
  created() {},
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.navbar-brand {
  padding-top: 0px;
}
</style>
