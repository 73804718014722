import { createStore } from "vuex";
import auth from "./modules/auth.js";
import user from "./modules/user.js";
import addresses from "./modules/addresses.js";
import filter from "./modules/filter.js";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    addresses,
    filter,
  },
});
