<template lang="html">
  <div class="float-end">
    <button
      type="button"
      class="btn btn-primary me-md-2"
      data-bs-toggle="modal"
      data-bs-target="#filterModal"
    >
      Filter ({{ filterCount }})
    </button>
    <button type="button" class="btn btn-secondary" @click="clearFilter()">
      Clear Filter
    </button>
    <div
      class="modal fade"
      id="filterModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
          </div>
          <div class="modal-body">
            <select
              class="form-select mb-3"
              aria-label="Default select example"
              v-model="selectedField"
            >
              <option selected disabled value="0">Kies Veldnaam</option>
              <option
                v-for="field in addressSchema"
                v-bind:key="field.name"
                :value="field.name"
              >
                {{ capitalizeFirstLetter(field.name) }}
              </option>
            </select>

            <select
              class="form-select mb-3"
              aria-label="Default select example"
              v-model="fieldOption"
              v-if="getFieldType == 'text'"
            >
              <option selected value="contains">Bevat</option>
              <option value="startswith">Start met</option>
              <option value="endswith">Eindigt met</option>
            </select>

            <div
              class="form-check form-switch"
              v-if="getFieldType == 'boolean'"
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                v-model="fieldValue"
              />
            </div>

            <input
              type="text"
              class="form-control"
              v-model="fieldValue"
              v-if="fieldIsText"
            />

            <div v-if="getFieldType == 'multipleselect'">
              <div v-for="radio in getMultiselectOptions" v-bind:key="radio">
                {{ radio }}
                <input
                  class="form-check-input"
                  type="radio"
                  :value="radio"
                  :name="selectedField"
                  :id="selectedField"
                  v-model="fieldValue"
                />
              </div>
            </div>

            <button
              type="button"
              class="btn btn-primary m-3"
              @click="addToFilter()"
              v-if="fieldValue !== null"
            >
              Toevoegen
            </button>

            <button
              type="button"
              class="btn btn-secondary m-3"
              @click="changeFilterAnd()"
              v-if="!filterAnd && filters.length > 0"
            >
              voldoe aan enkele waarden
            </button>

            <button
              type="button"
              class="btn btn-secondary m-3"
              @click="changeFilterAnd()"
              v-if="filterAnd && filters.length > 0"
            >
              voldoe aan alle waarden
            </button>

            <!-- <div class="border-top border-bottom p-3" v-if="filters.length > 0">
              <div v-for="filter in filters" v-bind:key="filter">
                {{ filter }}
              </div>
            </div> -->

            <table
              class="table table-striped table-hover"
              v-if="filters.length > 0"
            >
              <thead>
                <tr>
                  <th>Veldnaam</th>
                  <th>Optie</th>
                  <th>Waarde</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(filter, i) in filters" v-bind:key="filter">
                  <td>{{ filter.filterField }}</td>
                  <td>{{ filter.filterOption }}</td>
                  <td>{{ filter.filterValue }}</td>
                  <td>
                    <i
                      class="bi bi-trash text-danger"
                      @click="removeFilter(i)"
                      style="cursor: pointer"
                    >
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Sluiten
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressFilter",
  data() {
    return {
      filterAnd: false,
      selectedField: 0,
      fieldValue: null,
      fieldType: null,
      fieldOption: "contains",
    };
  },
  created() {
    this.$store.dispatch("fetchAddressSchema");
  },
  computed: {
    filters() {
      let filters = this.$store.getters.getFilter;
      return filters;
    },
    filterCount() {
      let filters = this.$store.getters.getFilter;
      let count = Object.keys(filters).length;
      return count;
    },
    addressSchema() {
      return this.$store.getters.getAddressSchema;
    },
    fieldIsBoolean() {
      let returnValue = false;
      this.addressSchema.forEach((item) => {
        if (item.name == this.selectedField && item.type == "boolean") {
          returnValue = true;
        }
      });
      return returnValue;
    },
    fieldIsText() {
      let returnValue = false;
      this.addressSchema.forEach((item) => {
        if (item.name == this.selectedField && item.type == "text") {
          returnValue = true;
        }
      });
      return returnValue;
    },
    fieldIsMultipleSelect() {
      let returnValue = false;
      this.addressSchema.forEach((item) => {
        if (item.name == this.selectedField && item.type == "multipleselect") {
          returnValue = true;
        }
      });
      return returnValue;
    },
    getFieldType() {
      let returnValue = false;
      this.addressSchema.forEach((item) => {
        if (item.name == this.selectedField) {
          returnValue = item.type;
        }
      });
      return returnValue;
    },
    getMultiselectOptions() {
      let returnValue = [];
      this.addressSchema.forEach((item) => {
        if (item.name == this.selectedField && item.type == "multipleselect") {
          returnValue = item.options.options.split(", ");
          console.log(returnValue);
        }
      });
      return returnValue;
    },
  },
  methods: {
    addToFilter() {
      let filterType = "";
      this.addressSchema.forEach((item) => {
        if (item.name == this.selectedField) {
          filterType = item.type;
        }
      });

      console.log(this.fieldType);
      let filterField = this.selectedField;
      let filterValue = this.fieldValue;
      let filterOption = this.fieldOption;
      let filter = {
        filterField: filterField,
        filterValue: filterValue,
        filterType: filterType,
        filterOption: filterOption,
      };
      this.buildFilter();
      this.$store.dispatch("addToFilter", filter);
      this.$store.dispatch("setFilterParams", this.buildFilter());
      this.$store.dispatch("fetchAddresses");
      this.fieldType = 0;
      this.fieldValue = "";
      this.fieldOption = "contains";
      this.selectedField = 0;
    },
    clearFilter() {
      this.$store.dispatch("clearFilter");
      this.$store.dispatch("fetchAddresses");
    },
    capitalizeFirstLetter(string) {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    buildFilter() {
      let filters = this.$store.getters.getFilter;
      // let filterParams = {
      //   "filter[POSTCODE][$regex]": "1094MH",
      //   "filter[HUISNR][$regex]": 476,
      // };
      let filterParams = [];
      filters.forEach((filter) => {
        let filterValue = filter.filterValue;
        let filterKey = "";

        if (filter.filterOption == "startswith") {
          filterValue = "^" + filterValue;
        } else if (filter.filterOption == "endswith") {
          filterValue = filterValue + "$";
        }

        console.log(filter.filterType);
        let regex = "[$regex]";
        let and = "[$and][]";
        let or = "[$or][]";
        if (filter.filterType == "multipleselect") {
          regex = "";
          and = "[$and][]";
          or = "[$or][]";
        } else if (filter.filterType == "boolean") {
          regex = "";
          and = "";
          or = "";
        }

        if (this.filterAnd) {
          filterKey = "filter" + and + "[" + filter.filterField + "]" + regex;
          // filterKey = "filter[" + filter.filterField + "][$regex]";
        } else {
          filterKey = "filter" + or + "[" + filter.filterField + "]" + regex;
          // filterKey = "filter[" + filter.filterField + "][$regex]";
        }

        if (filterKey in filterParams) {
          let oldValue = filterParams[filterKey];
          let newValue = oldValue + "|" + filterValue;
          filterParams[filterKey] = newValue;
        } else {
          filterParams[filterKey] = filterValue;
        }
      });
      console.log(filterParams);
      return filterParams;
    },
    changeFilterAnd() {
      this.filterAnd = !this.filterAnd;
      this.$store.dispatch("setFilterParams", this.buildFilter());
      this.$store.dispatch("fetchAddresses");
    },
    removeFilter(i) {
      this.$store.dispatch("removeFilter", i);
      this.$store.dispatch("setFilterParams", this.buildFilter());
      this.$store.dispatch("fetchAddresses");
    },
  },
};
</script>
