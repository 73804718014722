<template lang="html">
  <div class="float-end">
    <div
      class="modal fade"
      id="detailModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Adres</h5>
          </div>
          <div class="modal-body">
            <!-- {{ address }} -->
            <table class="table">
              <tbody>
                <tr v-for="(value, name) in address" v-bind:key="name">
                  <th scope="row">{{ name }}</th>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Sluiten
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressDetail",
  props: {
    address: Object,
  },
  created() {
    this.$store.dispatch("fetchAddressSchema");
  },
  computed: {
    getAddressSchema() {
      return this.$store.getters.getAddressSchema;
    },
  },
  methods: {
    startsWithUnderscore(string) {
      if (string.startsWith("_")) {
        console.log(string);
        return true;
      }
      return false;
    },
    getAddressValue(value) {
      return this.address[value];
    },
  },
};
</script>

<style lang="css" scoped></style>
